<template>
  <div>
    <div class="bg-white text-dark p-2 mb-3">
      <TInputCheckbox
        :checked="expected_payment_due_date_empty"
        @change="changeSwitch"
        :disabled="loading"
        label="No payment due date"
      />
    </div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      :store="store"
      @click-clear-filter="clearFilter"
      :resource="resource"
      enterable
      reloadable
      deletable
      @click-reload="refresh"
    >
      <template #first_item="{ item }">
        <td>
          <SCardProductItems
            v-if="item.first_item"
            :item="item.first_item.product"
            resource="/sale/goods/products"
            :itemsCount="item.items_count"
            widthAuto
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <SMessageOrderStatus :id="item.status_id" />
          <SMessageIkomOrderStatus :id="item.primary_status" />
        </td>
      </template>
      <template #customer_id="{ item }">
        <td>
          <TLink
            v-if="item.customer"
            :to="lodash.getReferenceLink('user', item.customer_id)"
            :content="item.customer.name"
          />
        </td>
      </template>
      <template #cost_of_goods="{ item }">
        <td>
          <TMessageMoney
            :amount="item.cost_of_goods"
            :currency="item.purchase_cost_currency_id"
          />
        </td>
      </template>
      <template #shipment_method_id="{ item }">
        <td>
          <SMessageShipmentMethod :id="item.shipment_method_id" />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" noTranslate />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>
      <template #payment_due_date="{ item }">
        <td>
          <TMessageDateTime :content="item.payment_due_date" dateOnly />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Order Id"
        />
      </template>
      <template #first_item-filter>
        <TInputText
          :value.sync="filter['items.product_id']"
          @update:value="filterChange"
          placeholder="Jancode"
        />
      </template>
      <template #customer_id-filter>
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #shipment_method_id-filter>
        <SSelectShipmentMethod
          :value.sync="filter.shipment_method_id"
          @change="filterChange"
        />
      </template>
      <template #status-filter>
        <SSelectOrderStatus
          class="text-truncate-0"
          :store="orderStatusStore"
          :value.sync="filter.status_id"
          @change="filterChange"
        />
      </template>
      <template #created_at-filter>
        <TInputDateTimeRange
          :value.sync="filter_between['created_at']"
          @update:value="filterChange"
        />
      </template>
      <template #payment_due_date-filter>
        <TInputDateTimeRange
          :value.sync="filter_between['payment_due_date']"
          @update:value="filterChange"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    orderStatusStore: {
      type: String,
      required: true,
      default: "status",
    },
  },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "id",
          label: "Order Id",
          _style: "width: 140px; min-width: 140px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "first_item",
          label: "Product",
          _style: "width: 280px; min-width: 280px",
        },
        {
          key: "customer_id",
          label: "Customer",
          _classes: "text-nowrap",
          _style: "width: 250px; min-width: 250px",
        },
        {
          key: "cost_of_goods",
          label: "Cost of goods",
          _style: "width: 170px; min-width: 170px",
        },
        {
          key: "shipment_method_id",
          label: "Shipment method",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "payment_due_date",
          label: "Payment due date",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "created_at",
          sorter: true,
          label: "Created at",
          _style: "width: 220px",
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 220px; min-width: 220px",
        },
      ],
      filter: {},
      filter_between: {},
      expected_payment_due_date_empty: false,
    };
  },
  mounted() {
    this.$store.dispatch(`${this.store}.fetch.if-first-time`);
  },
  computed: {
    list() {
      return this.$store.getters[`${this.store}.list`];
    },
    loading() {
      return this.$store.getters[`${this.store}.loading`];
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch(`${this.store}.fetch`);
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...this.formatFilterBetween(this.filter_between),
        "filter[expected_payment_due_date_empty]":
          this.expected_payment_due_date_empty,
      });
      this.$store.dispatch(`${this.store}.apply-query`, filterFields);
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.filterChange();
    },
    changeSwitch(value) {
      this.expected_payment_due_date_empty = value;
      this.filterChange();
    },
    formatFilterBetween(obj) {
      return Object.entries(obj).reduce((p, c) => {
        p[`filter_between[${c[0]}]`] = [c[1].start || 0, c[1].end || 0].join(
          ","
        );
        return p;
      }, {});
    },
  },
};
</script>
