<template>
  <div>
    <SButtonCreateOrder class="mb-3" to="payment/new" />
    <OrderList
      store="order.order_payment"
      resource="/sale/orders/payment"
      orderStatusStore="order.payment_statuses"
    />
  </div>
</template>

<script>
import OrderList from "../components/OrderList.vue";

export default {
  components: { OrderList },
};
</script>
